import React, { useState, useRef, useEffect } from 'react';
import { Box, Image, IconButton, Icon, Text, Flex } from '@chakra-ui/react';
import JaxImage from '../assets/jax.png'; // Ensure you import the image correctly
import { MdPlayArrow, MdPause } from 'react-icons/md'; // Import play and pause icons
import AudioBG from '../assets/bgmusic.mp3'
import MusicBars from './MusicBars';
import '../App.css'


function SmallWidget() {
    const [isPlaying, setIsPlaying] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false); // State to control expansion
    const audioRef = useRef(new Audio(AudioBG)); // Path to your music file
    const [volume, setVolume] = useState(20); // Volume state (0 to 100)


    useEffect(() => {
      audioRef.current.loop = true;
      audioRef.current.volume = volume / 100; // Convert volume to a scale of 0.0 to 1.0
    }, [volume]);
  
    const togglePlayPause = () => {
        setIsExpanded(!isExpanded); // Toggle expansion
        setIsPlaying(!isPlaying);
        isPlaying ? audioRef.current.pause() : audioRef.current.play();
      };
  
    return (
      <Box
        position="fixed"
        bottom={10}
        zIndex={10}
        left="2%"
        borderRadius="5px"
        boxShadow="lg"
        p={4}
        className='play'
        bg="white"
        // Adjust the padding top to create space for the image sticking out
        pt="60px" // Adjust this value based on the size of the image
        textAlign="center" // Center the content inside the box
        width={isExpanded ? "500px" : "200px"} // Expand width
        height={30}
        animation="fadeInAnimationT 4s forwards"
        sx={{
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
          transition: "width 0.5s ease-out" // Smooth transition for width
        }}
      >
        {/* Position the image so that it sticks out of the top of the widget */}
        <Flex align="center" justify="center" height="100%">
        <Image
          src={JaxImage}
          alt="Jax"
          position="absolute"
          bottom={0}
          left={0}
          zIndex={11}
          // Set a specific width and height if needed
          width="130"
          height="auto"
        />
        {isExpanded && (
          <Flex>
            <MusicBars /> {/* Music Wave Bars */}

            <Box ml="100px" mt={-10} align="left"> {/* Margin left for spacing */}
              <Text fontFamily="thick">USURAHI SHINJUU</Text>
              <Text fontSize="15px" textColor="#7e7e7e">林原 めぐみ</Text>
            </Box>
          </Flex>
        )}
        {/* Circle with Play Button */}
        <IconButton
          aria-label={isPlaying ? "Pause" : "Play"}
          icon={<Icon as={isPlaying ? MdPause : MdPlayArrow} color="white" boxSize="24px" />}
          position="absolute"
          right="-23px" // Adjust this value as needed
          top="50%"
          transform="translateY(-50%)"
          borderRadius="full"
          size="lg"
          bgColor="#ef8d34"
          boxShadow= "0px 0px 10px rgba(0, 0, 0, 0.5)" // Custom box shadow with RGBA
          onClick={togglePlayPause}
          _hover= { {bg: "#b36c2b"}}
        />
        </Flex>
      </Box>
    );
  }

  export default SmallWidget;
