// useDeviceCheck.js
import { useEffect } from 'react';

const useDeviceCheck = () => {
    useEffect(() => {
        const userAgent = navigator.userAgent;

        // Check for mobile and tablet devices
        if (/android/i.test(userAgent) || /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            // Redirect or handle the logic for mobile devices
            window.location.href = '/mobile-not-allowed'; // Redirect to a specific route
        }
    }, []);
};

export default useDeviceCheck;
