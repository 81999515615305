// App.js
import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom';
import useDeviceCheck from './components/useDeviceCheck'; // Your custom hook
import MobileNotAllowed from './components/NotAllowed'; // Your mobile not allowed component
import App from './App'; // Replace with your main component
import SliderComponent from './components/SliderComponent';

function OtherApp() {
  useDeviceCheck();

  return (
    <Router>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/mobile-not-allowed" element={<MobileNotAllowed />} />
        <Route path="/game" element={<SliderComponent />} />
        {/* Add more routes as needed */}
      </Routes>
    </Router>
  );
}

export default OtherApp;
