import React from 'react';
import { Grid, Box, Image, Text, Heading, Divider } from '@chakra-ui/react';
import zetman from '../assets/sneaks/10.jpg';
import joey from '../assets/sneaks/6.jpg';
import steven from '../assets/sneaks/Steven.jpg';
import brunno from '../assets/sneaks/8.jpg';
import bg from '../assets/background2.png'
import { useInView } from 'react-intersection-observer';

const teamMembers = [
  {
    name: 'Joey (@JoeyDodd5)',
    description: 'The lead artist and co-founder is Joey, a talented individual who worked with Marvel, DC, and other brands before co-founding @inkonbtc.',
  },
  {
    name: 'Zetman (@zetman_eth)',
    description: 'Zetman, a co-founder of @inkonbtc, is a Senior Engineer in Silicon Valley and a real Bitcoin maxi who also collects art!',
  },
  {
    name: 'Steven (@StevenComicMan)',
    description: 'Steven, another co-founder, is actively engaged with the community. He is known for occasionally sharing insider news about the project.',
  },
  {
    name: 'Brunno (@BrunnoEth)',
    description: 'Brunno, a co-founder and key community figure, has been instrumental in building the community.',
  }
];

const CoreTeam = () => {
    const [ref, inView] = useInView();
  return (
    
    <Box
      ref={ref}
      w="100%"
      p={5}
      borderRadius="md"
      transition="opacity 2s ease-out, transform 0.5s ease-out"
      opacity={inView ? 1 : 0}
      transform={inView ? 'translateY(0)' : 'translateY(-100px)'} // Adjust the value for the desired 'from above' effect
    >
    <Box p={5} color="white" backgroundImage={bg} backgroundSize="100%" backgroundRepeat="no-repeat" id="team">
      <Heading mb={4} textAlign="center" fontSize="xxx-large">INKonBTC</Heading>
      <Text mb={4} textAlign="center" fontSize="xx-large">Team</Text>
    <Divider mx="auto" my={30} width="30%" justifyContent="center" position="relative" alignItems="center" borderColor="gray.200" />
      <Grid templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }} gap={6}>
      {teamMembers.map((member, index) => (
          <Box key={member.name} textAlign="center" marginTop="10%">
            <Image 
              src={index === 0 ? joey : index === 1 ? zetman : index === 2 ? steven : brunno}  
              alt={member.name} 
              boxSize="400px" 
              objectFit="cover" 
              borderRadius="full" 
              mx="auto"
            />
            <Box marginLeft="25%" w="50%">
            <Text fontWeight="bold" fontSize="xx-large" mt="10%">{member.name}</Text>
            <Text fontSize="x-large" >{member.description}</Text>

            </Box>
          </Box>
        ))}
      </Grid>
    </Box></Box>
  );
};

export default CoreTeam;
