// MobileNotAllowed.js
import React from 'react';

const MobileNotAllowed = () => (
    <div>
        <p>Sorry, this website is only accessible from laptops or PCs.</p>
    </div>
);

export default MobileNotAllowed;
