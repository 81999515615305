import React from 'react'
import './musicbars.scss'

function MusicBars() {
  return (
    <div id="wrapper">

			<div class="equalizer">

				<span class="equalizer-item equalizer-1"></span>
				<span class="equalizer-item equalizer-2"></span>
				<span class="equalizer-item equalizer-3"></span>
				<span class="equalizer-item equalizer-none"></span>

			</div>	

		</div>
  )
}

export default MusicBars