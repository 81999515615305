import React, { useEffect } from 'react';
import { Flex, Box, Image, Text, Heading, Center} from '@chakra-ui/react';
import road from '../assets/Art.png'
import { useInView } from 'react-intersection-observer';

const ProjectPhases = () => {
  const [ref, inView] = useInView();

  return (
    
    <Box
      ref={ref}
      w="100%"
      p={5}
      borderRadius="md"
      transition="opacity 2s ease-out, transform 0.5s ease-out"
      opacity={inView ? 1 : 0}
      transform={inView ? 'translateY(0)' : 'translateY(-100px)'} // Adjust the value for the desired 'from above' effect
    >
    <Flex Flex direction={{ base: "column", md: "row" }} w="100%" id="projectPhases" >
      {/* Image Container */}
      <Center w={{ base: "100%", md: "50%" }} p={5}>
        <Image 
          src={road} // Replace with your image path
          alt="Project Phase Image"
          w="80%"
          h="80%"
          objectFit="cover" // This ensures the image covers the entire container
        />
      </Center>

      {/* Text Container */}
      <Box w="50%" p={5} textAlign="center" marginTop="5%" >
        <Box w="50%" marginLeft="25%">
                  
        <Heading color="white" mb={4}>Phase 1</Heading>
        <Text color="white" fontSize="xl">
          {/* Your text content goes here */}
          This phase features a 1/1 collection created by the lead artist, Joey. Individuals who are ink-listed will have the opportunity to collaborate directly with Joey, contributing to the creation of personalized INKonBTC pieces.

          {/* Add more text as needed */}
        </Text>
        <Heading marginTop="20%" color="white" mb={4}>Phase 2</Heading>
        <Text color="white" fontSize="xl">The second phase is anticipated to be a generative collection. It aims to emphasize community involvement, reinforcing the idea that INK is accessible and intended for a wide audience.</Text>
        {/* You can add more text or elements here */}
                  
        <Heading marginTop="20%" color="white" mb={4}>Phase 3</Heading>
        <Text color="white" fontSize="xl">
          {/* Your text content goes here */}
          The details of the third phase are currently in development and will be announced in the future. This phase is expected to add another dimension to the project, with specifics to be revealed at a later stage.

          {/* Add more text as needed */}
        </Text>
        </Box>
      </Box>
    </Flex></Box>
  );
};

export default ProjectPhases;
