import React from 'react';
import { Flex, Button, Box, useColorModeValue, Image } from '@chakra-ui/react';
import Logo from "../assets/logo.png";
import "../App.css"
import { Link } from 'react-scroll';

function Navbar() {

  const CustomButton = ({ children }) => (
    <Button 
      bg="rgba(255, 255, 255, 0.1)" 
      width="80px" 
      textColor="white" 
      sx={{ 
        fontSize: "14px", 
        fontFamily: 'BadeFont', 
        height: "30px", 
        _hover: { bg: "rgba(0, 0, 0, 0.3)" }
      }}
    >
      {children}
    </Button>
  );

  return (
    <Flex
      as="nav"
      align="center"
      justify="center" // Center the items
      wrap="wrap"
      padding="10px"
      bg="Transparent"
      className='nav'
    >
      
      <Image 
        src={Logo}
        width="2%"
        borderRadius="10px"
        mx="2rem"
        marginLeft="20%" // Add margin to left and right
      />
        
      {/* Left Buttons */}
      <Box display="flex" gap="4" marginRight="19%" marginTop="20px" marginLeft="auto" > {/* Adjust for alignment */}
        <CustomButton><Link to="projectPhases" smooth={true} duration={500}>
        About
      </Link></CustomButton>
        <CustomButton><Link to="team" smooth={true} duration={500}>
        Team
      </Link></CustomButton>
        <CustomButton>Ordinals</CustomButton>
        <CustomButton>$INK</CustomButton>
        <CustomButton>Discord</CustomButton>
        <CustomButton>Twitter</CustomButton>
        <Button 
      bg="rgba(255, 255, 255, 0.1)" 
      width="140px" 
      textColor="white" 
      sx={{ 
        fontSize: "14px", 
        fontFamily: 'BadeFont', 
        height: "30px", 
        _hover: { bg: "rgba(0, 0, 0, 0.3)" }
      }} >Connect Wallet</Button>
      </Box>
    </Flex>
  );
}

export default Navbar;
