
import './App.css';
import React, { useState, useEffect } from 'react';
import Navbar from './components/Navbar';
import { Flex, Box, Image, Text, Fade } from '@chakra-ui/react';
import SmallWidget from './components/Play'
import TextHeader from "./assets/TextHeader.png"
import useDeviceCheck from './components/useDeviceCheck';

import AnimatedCursor from "react-animated-cursor";
import SliderComponent from './components/SliderComponent';
import image1 from './assets/sneaks/1.jpg'
import CoreTeam from './components/Team';
import ProjectPhases from './components/Phases';

function App() {
  const [progress, setProgress] = useState(0);
  const [loadingComplete, setLoadingComplete] = useState(false);

  useDeviceCheck(); // This will run the check on component mount


  useEffect(() => {
    if (progress < 40) {
      const timer = setTimeout(() => setProgress(progress + 1), 50); // Increment progress
      return () => clearTimeout(timer);
    } else {
      setLoadingComplete(true); // Set loading to complete
    }
  }, [progress]);

  if (loadingComplete) {
    return <Hero />;
  }

  return (
    
    <Flex justify="center" bgColor="#111111" align="center" height="100vh">
      <AnimatedCursor
          innerSize={8}
          outerSize={8}
          color="239, 141, 39"
          outerAlpha={0.2}
          innerScale={0.7}
          outerScale={5}
          clickables={[
            "a",
            'input[type="text"]',
            'input[type="email"]',
            'input[type="number"]',
            'input[type="submit"]',
            'input[type="image"]',
            "label[for]",
            "select",
            "textarea",
            "button",
            ".link",
          ]}
        />
      <div className="wrapper">
        <div class="typing-demo">INK ON BTC</div>
      </div>
    </Flex>
  );
}

function Hero() {
  return (
    <div>
      <AnimatedCursor
    innerSize={8}
    outerSize={8}
    color="239, 141, 39"
    outerAlpha={0.2}
    innerScale={0.7}
    outerScale={5}
    clickables={[
      "a",
      'input[type="text"]',
      'input[type="email"]',
      'input[type="number"]',
      'input[type="submit"]',
      'input[type="image"]',
      "label[for]",
      "select",
      "textarea",
      "button",
      ".link",
    ]}
  />
    <div className="background">
      <Navbar /> {/* Include the Navbar */}
      <Box position="absolute" top="50%" left="50%"  animation="fadeInAnimationT 3s forwards" >
        <Box transform="translate(-50%, -50%)">

      <Image 
          src={TextHeader}
        />
      <Text marginTop="5%" color="#fff" fontSize="50px" fontFamily="cp" textAlign="center" letterSpacing="-2px" >Art for arts sake, INK is for the people</Text>
          
          </Box>
      </Box>
      <SmallWidget />
    </div>

    <Flex backgroundColor="#0b0b0b" direction="column">
    
    <SliderComponent/>
    <ProjectPhases/>
    <CoreTeam/>
    
    <Flex marginTop="5%"> test</Flex>
    </Flex>

    </div>
  );
}

export default App;
