import React from 'react';
import './SliderComponent.css';
import image1 from '../assets/sneaks/1.jpg';
import image2 from '../assets/sneaks/2.jpg';
import image3 from '../assets/sneaks/3.jpg';
import image4 from '../assets/sneaks/4.jpg';
import image5 from '../assets/sneaks/5.jpg';
import image6 from '../assets/sneaks/6.jpg';
import image7 from '../assets/sneaks/7.jpg';
import image8 from '../assets/sneaks/8.jpg';
import image9 from '../assets/sneaks/9.jpg';
import image10 from '../assets/sneaks/10.jpg';
import { Image } from '@chakra-ui/react';

const images = [
    image1,
    image8,
    image9,
    image10,
    image7,
    image6,
    image5,
    image4,
    image3,
    image2,
    image1,
    image8,
    image9,
    image10,
    image7,
    image6,
    image5,
    image4,
    image3,
    image2,
    image1,
    image8,
    image9,
    image10,
    image7,
    image6,
    image5,
    image4,
    image3,
    image2,
    image1,
    image8,
    image9,
    image10,
    image7,
    image6,
    image5,
    image4,
    image3,
    image2,
    image1,
    image8,
    image9,
    image10,
    image7,
    image6,
    image5,
    image4,
    image3,
    image2,
    image1,
    image8,
    image9,
    image10,
    image7,
    image6,
    image5,
    image4,
    image3,
    image2,
    image1,
    image8,
    image9,
    image10,
    image7,
    image6,
    image5,
    image4,
    image3,
    image2,
    image1,
    image8,
    image9,
    image10,
    image7,
    image6,
    image5,
    image4,
    image3,
    image2,
    image1,
    image8,
    image9,
    image10,
    image7,
    image6,
    image5,
    image4,
    image3,
    image2,
    image1,
    image8,
    image9,
    image10,
    image7,
    image6,
    image5,
    image4,
    image3,
    image2,
    image1,
    image8,
    image9,
    image10,
    image7,
    image6,
    image5,
    image4,
    image3,
    image2,
    image1,
    image8,
    image9,
    image10,
    image7,
    image6,
    image5,
    image4,
    image3,
    image2,
    image1,
    image8,
    image9,
    image10,
    image7,
    image6,
    image5,
    image4,
    image3,
    image2,
    image1,
    image8,
    image9,
    image10,
    image7,
    image6,
    image5,
    image4,
    image3,
    image2,
    image1,
    image8,
    image9,
    image10,
    image7,
    image6,
    image5,
    image4,
    image3,
    image2,
    image1,
    image8,
    image9,
    image10,
    image7,
    image6,
    image5,
    image4,
    image3,
    image2,
    image1,
    image8,
    image9,
    image10,
    image7,
    image6,
    image5,
    image4,
    image3,
    image2,
    // ... add all image paths
];

const SliderComponent = () => {
    return (
        <div className="slider-container">
            <div className="slider-wrapper">
                {images.map((src, index) => (
                    <div key={index} className="image-container">
                        <img src={src} alt={`Slide ${index + 1}`} />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default SliderComponent;
